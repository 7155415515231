<template>
  <div class="wrapper">
    <tree-to-vliew></tree-to-vliew>
    <right-content>
      <div class="right-content_head">
        <span class="title">{{ departmentName }}: 共有党员质量监督岗{{ total }}个</span>
        <el-button @click="exportList">导出成员</el-button>
      </div>
      <div class="right-content_table">
        <div class="table">
          <my-table
            @currentPage="currentPage"
            :tableInfo="tableInfo"
            :colConfigs="colConfigs"
          >
            <el-table-column
              width="260px"
              align="center"
              slot="option"
              label="操作"
            >
              <template slot-scope="{ row }">
                <div class="option">
                  <el-button
                    icon="iconfont icon-chakan-copy1"
                    @click="check(row)"
                    >查看</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </my-table>
        </div>
      </div>
    </right-content>
  </div>
</template>

<script>
import MyTable from "@/components/MyTable";
import TreeToVliew from "../../commonViews/TreeToVliew";
import { getSentryList,exportUser } from "@/api";
import RightContent from "../../commonViews/RightContent";
import {exportFun} from '@/utils/params'
export default {
  name: "supervision",
  components: { RightContent, TreeToVliew, MyTable },
  created() {
    this.getSentrysData();
    if(localStorage.getItem("userInfo")){
      this.departmentName = JSON.parse(localStorage.getItem("userInfo")).name
    }
  },
  async activated () {
    await this.getSentrysData();
  },
  methods: {
    async exportList(){
      const param = {
        type:3,
        department_id:localStorage.getItem("department_id")
      }
      exportFun(exportUser,param,"exportUser")
    },
    //分页
    currentPage(current) {
      this.page = current;
      this.getSentrysData();
    },
    async getSentrysData() {
      const res = await getSentryList({
        department_id: localStorage.getItem("department_id"),
        page: this.page,
      });
      if (res.status === 200) {
        this.tableInfo = res.data.data;
        this.total = res.data.data.total;
      }
    },
    check({ id,name }) {
      this.$router.push({
        path: "/supervision/list-detail",
        query: {
          id,
          name
        },
      });
    },
  },
  data() {
    return {
      tableInfo: {},
      departmentName:"",
      total:0,
      page: 1,
      paginConfigs: {},
      colConfigs: [
        { prop: "id", label: "序号", width: "80px" },
        { prop: "name", label: "监督岗名称" },
        { prop: "sentries_number", label: "参与人数" },
        { slot: "option" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  .right-content_head {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    span {
      margin-bottom: 20px;
    }
    button {
      background-color: red;
      color: #fff;
      width: 150px;
      padding: 10px 25px;
    }
  }
  .right-content_table {
    .table {
      margin: 20px 0;
    }
    button {
      padding: 7px 15px;
      background: #fde5e4;
      border-radius: 4px;
      border: 0;
      font-size: 15px;
      font-family: PingFang SC;
      color: #e32322;
    }
  }
}
</style>
